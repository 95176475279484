import { styled } from 'styles/stitches.config'
import React, { forwardRef } from 'react'
import { SkeletonSet, Skeleton, Bone } from 'components/SkeletonKit'
import StoreCard from 'components/pages/explore/stores/StoreCard'
import { StoreByDistance } from 'types/api/store'
import { v4 as uuid } from 'uuid'

const Wrapper = styled('div', {
  ['& > *:not(:last-child)']: {
    marginBottom: '$m',
  },
})

const Container = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$s',
  boxShadow: '$elevationMedium',
  display: 'block',
  overflow: 'hidden',
})

const Figure = styled('figure', {
  overflow: 'hidden',
})

const FigCaption = styled('figcaption', {
  lineHeight: '1.5rem',
  margin: '$m 0',
  padding: '0 $s',
})

const ImagePlaceholder = styled('div', {
  position: 'relative',
  '&::before': {
    content: '',
    display: 'block',
    paddingTop: '40%',
  },
})

const Placeholder = () => {
  const arr = new Array(5).fill('')

  return (
    <>
      {arr.map(() => (
        <Container key={uuid()}>
          <SkeletonSet>
            <Skeleton>
              <Figure>
                <ImagePlaceholder>
                  <Bone
                    css={{
                      height: 'auto',
                      position: 'absolute',
                      inset: 0,
                    }}
                  />
                </ImagePlaceholder>
              </Figure>
              <FigCaption>
                <Bone variant="heading3" />
                <Bone variant="body3" />
              </FigCaption>
            </Skeleton>
          </SkeletonSet>
        </Container>
      ))}
    </>
  )
}

type Props = {
  stores?: StoreByDistance[]
  showLike?: boolean
}

const StoreList = forwardRef<HTMLDivElement, Props>(
  ({ stores, showLike }, ref) => {
    if (!stores || stores.length === 0) {
      return (
        <Wrapper aria-label="store-list-placeholder" ref={ref}>
          <Placeholder />
        </Wrapper>
      )
    }

    return (
      <Wrapper aria-label="store-list" ref={ref}>
        {stores?.map((store) => (
          <StoreCard key={store.id} store={store} showLike={showLike} />
        ))}
      </Wrapper>
    )
  }
)

StoreList.displayName = 'StoreList'
export default StoreList
