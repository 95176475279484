import dynamic from 'next/dynamic'

import ImageFadeIn from 'components/ImageFadeIn'
import FavoriteButton from 'components/FavoriteButton'

import { styled } from 'styles/stitches.config'
import { H4 } from 'design-system'

import type { StoreDetailsOnMap } from 'types/api/store'

const StoreTags = dynamic(
  () => import('components/pages/explore/stores/StoreTags'),
  { ssr: false }
)

const Figure = styled('figure', {
  backgroundColor: '$white',
  overflow: 'hidden',
  position: 'relative',
})

const Container = styled('figcaption', {
  paddingBottom: '$m',
})

const StoreName = styled(H4, {
  color: '$grey1000',
  margin: '0 $s',
})

const Address = styled('span', {
  color: '$grey800',
  fontSize: '$body2',
  margin: '$s',
  textTransform: 'capitalize',
})

const FavoriteButtonWrapper = styled('div', {
  position: 'absolute',
  top: '$s',
  left: '$s',
})

type StoreInfoProps = {
  store: StoreDetailsOnMap
  showLike?: boolean
}

const StoreInfo = ({ store, showLike }: StoreInfoProps) => {
  return (
    <Figure aria-label="store-info">
      <ImageFadeIn
        alt={`${store.name} Banner Image`}
        aspectRatio={40}
        src={store.image || '/images/coffee-shop-default.jpg'}
      />
      {!!showLike && (
        <FavoriteButtonWrapper>
          <FavoriteButton storeId={store.id} like={store.favorite} />
        </FavoriteButtonWrapper>
      )}
      <Container>
        <StoreTags
          store={store}
          mi={store.mi}
          css={{ margin: '0 -$xxxs', padding: '$s $s $xxs' }}
        />
        <StoreName>{store.name}</StoreName>

        <Address>{store.address}</Address>
      </Container>
    </Figure>
  )
}

export default StoreInfo
